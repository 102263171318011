<template>
  <div id="error-page-layout">
    <img src="@/assets/images/errorPage/tip.png" style="width: 120px;margin-top: 40%;" />
    <div style="margin-top: 10%;white-space: pre-wrap;word-break: break-word;">
      Authorization expired, please return to the app to reopen the page
    </div>
    <el-button round @click="goApp()" style="margin-top: 20%;width: 50%;background-color: rgba(0, 111, 255, 1);color: white;">
      {{$t('common.i_goBack')}}
    </el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goApp() {
      console.log(localStorage.getItem("appScheme"));
      window.location.href = localStorage.getItem("appScheme");
    }
  }
};
</script>

<style>
#error-page-layout {
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/index/indexHeaderBg.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
</style>
