<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="invite-layout">
      <div style="padding: 8%;">
        <div style="text-align: left;">
          <img src="@/assets/common/bejoyitIcon.png" style="width: 60px;vertical-align: middle;margin-right: 10px;" />
          <span style="vertical-align: middle;font-size: 24px;">“Be Joy it”</span>
        </div>
        <div style="text-align: left;margin-top: 4%;line-height: 30px;">
          {{$t('all.i_friendInvitesHisTeam')}}
          <br />
          {{$t('all.i_moreCash')}}
          <br />
          {{$t('all.i_moreCash')}}
          <br />
          {{$t('all.i_moreCash')}}
          <div>
            <img src="@/assets/images/invite/giftCardImg.png" style="width: 150px;margin-top: 10px;" />
          </div>
        </div>
        <div style="text-align: right;">
          <img src="@/assets/images/invite/inviteBBG.png" style="width: 100px;margin-top: -150px;margin-bottom: -20px;" />
        </div>
        <div style="background-color: rgba(57, 126, 98, 1);border-radius: 20px;padding: 8%;position: relative;">
          <img :src="taskInfo.appIcon" style="width: 60px;vertical-align: middle;margin-bottom: 5%;border-radius: 12px;" />
          <div style="color: white;">{{$t('all.i_joinTask')}}</div>
          <div style="color: rgba(249, 217, 74, 1);font-size: 32px;margin-top: 12%;">${{taskInfo.taskRewardNum}}</div>
          <el-button @click="goInviteTask()" style="width: 100%;border-radius: 15px;background-color: rgba(239, 187, 82, 1);color: rgba(90, 47, 19, 1);margin-top: 5%;border: none;font-weight: bold;">
            {{$t('all.i_joinTeamNow')}}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      inviteCode: "",
      taskInfo: {}
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color: rgba(223, 227, 252, 1);");
  },
  beforeDestroy() {
    document
      .querySelector("body")
      .removeAttribute("style", "background-color: rgba(223, 227, 252, 1);");
  },
  methods: {
    goInvite() {
      this.isLoading = true;
      if (this.$route.query.tid != null && this.$route.query.tid != "") {
        this.$axios({
          url: "/webCash/invite",
          method: "get",
          params: {
            tid: this.$route.query.tid
          }
        }).then((result) => {
          if (result.data.code == 1) {
            this.isLoading = false;
            this.taskInfo = result.data.data.taskInfo;
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
            this.$router.push("/index");
          }
        });
      }
    },
    goInviteTask() {
      let token = localStorage.getItem("token");
      if (token) {
        this.$router.push({
          path: "/taskInfo",
          query: {
            tid: this.$route.query.tid,
            referrer: this.$route.query.referrer
          }
        });
      } else {
        this.$router.push({
          path: "/login",
          query: {
            tid: this.$route.query.tid,
            referrer: this.$route.query.referrer
          }
        });
      }
    }
  },
  created() {
    this.goInvite();
    this.inviteCode = this.$route.query.referrer;
  }
};
</script>

<style>
#invite-layout {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 500px;
  background-color: rgba(223, 227, 252, 1);
}
</style>
