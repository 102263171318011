<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="index-layout">
      <div class="index-top">
        <!-- 导航条 -->
        <div class="i-page-header">
          <img src="@/assets/common/bejoyitIcon.png" style="float: left;width: 36px;" />
          <div style="float: right;">
            <div style="background-color: rgba(251, 236, 143, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 10px;">
              <span style="font-size: 14px;color: black;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(87, 36, 13, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
            </div>
            <div @click="userInfoDialog = true" style="width: 36px;height: 36px;display: inline-block;vertical-align: middle;">
              <img src="@/assets/images/index/avatar.png" style="width: 36px;height: 36px;border-radius: 30px;" />
              <img :src="userInfo.avatar" style="width: 36px;height: 36px;border-radius: 30px;margin-left: -36px;" />
            </div>
          </div>
        </div>

        <p style="border: 1px solid rgba(240, 240, 240, 1);padding: 0;margin: 3% 0;"></p>

        <div style="overflow: hidden;margin: auto 4%;">
          <div @click="$router.push('/convert')" style="background-color: rgba(223, 227, 254, 1);float: left;padding: 3%;border-radius: 15px;text-align: left;width: 41%;">
            <span style="font-size: 12px;color: rgba(102, 104, 120, 1);">{{$t('all.i_variousGifts')}}</span>
            <br />
            <span style="font-size: 16px;color: rgba(25, 38, 202, 1);font-weight: bold;">{{$t('all.i_redeemNow')}}</span>
            <br />
            <img src="@/assets/images/index/convertIcon.png" style="float: right;width: 40%;" />
          </div>
          <div @click="$router.push('/cashLog')" style="background-color: rgba(254, 248, 206, 1);float: right;padding: 3%;border-radius: 15px;text-align: left;width: 41%;">
            <span style="font-size: 12px;color: rgba(102, 104, 120, 1);">{{$t('all.i_couponDetails')}}</span>
            <br />
            <span style="font-size: 16px;color: black;font-weight: bold;">{{$t('all.i_rewardRecords')}}</span>
            <br />
            <img src="@/assets/images/index/logIcon.png" style="float: right;width: 40%;" />
          </div>
        </div>

        <div style="background-color: rgba(244, 245, 247, 1);margin: 4%;padding: 4% 4%;border-radius: 15px;">
          <div style="text-align: left;">
            <span style="font-size: 24px;color: rgba(107, 115, 127, 1);">{{$t('all.i_leaderboard')}}</span>
            <div style="font-size: 12px;margin-left: -5px;margin-top: 12px;margin-bottom: 12px;">
              <span :class="leaderboardType == 1 ? 'leaderboardAct' : ''" @click="leaderboardType = 1;" style="margin: auto 4px;padding: 4px 8px 4px 6px;">{{$t('all.i_todayReward')}}</span>
              <span :class="leaderboardType == 2 ? 'leaderboardAct' : ''" @click="leaderboardType = 2;" style="margin: auto 4px;padding: 4px 8px 4px 6px;">{{$t('all.i_totalReward')}}</span>
              <span :class="leaderboardType == 3 ? 'leaderboardAct' : ''" @click="leaderboardType = 3;" style="margin: auto 4px;padding: 4px 8px 4px 6px;">{{$t('all.i_friends')}}</span>
            </div>
          </div>
          <div v-if="leaderboardType == 1">
            <div v-for="(leaderboard, index) in todayCashLeaderboard" :key="leaderboard.id">
              <div style="overflow: hidden;margin: 3% auto;">
                <div style="float: left;background-color: rgba(36, 76, 49, 1);width: 45px;height: 45px;border-radius: 30px;position: relative;margin-top: 8px;margin-left: 8px;">
                  <img :src="leaderboard.avatar" style="width: 100%;border-radius: 30px;background-color: white;" />
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -6px;top: -6px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle;font-size: 12px;">{{index + 1}}</span>
                  </span>
                </div>
                <div style="float: left;margin-left: 20px;margin-top: 12px;text-align: left;">
                  <span style="color: rgba(58, 62, 74, 1);font-size: 15px;font-weight: 600;">{{leaderboard.userName}}</span>
                  <br />
                  <span style="color: rgba(96, 103, 118, 1);font-size: 13px;">{{leaderboard.num}}</span>
                  <img src="@/assets/common/cash1Icon.png" style="width: 14px;vertical-align: middle;" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="leaderboardType == 2">
            <div v-for="(leaderboard, index) in cashLeaderboard" :key="leaderboard.id">
              <div style="overflow: hidden;margin: 3% auto;">
                <div style="float: left;background-color: rgba(36, 76, 49, 1);width: 45px;height: 45px;border-radius: 30px;position: relative;margin-top: 8px;margin-left: 8px;">
                  <img :src="leaderboard.avatar" style="width: 100%;border-radius: 30px;background-color: white;" />
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -6px;top: -6px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle;font-size: 12px;">{{index + 1}}</span>
                  </span>
                </div>
                <div style="float: left;margin-left: 20px;margin-top: 12px;text-align: left;">
                  <span style="color: rgba(58, 62, 74, 1);font-size: 15px;font-weight: 600;">{{leaderboard.userName}}</span>
                  <br />
                  <span style="color: rgba(96, 103, 118, 1);font-size: 13px;">{{leaderboard.num}}</span>
                  <img src="@/assets/common/cash1Icon.png" style="width: 14px;vertical-align: middle;" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="leaderboardType == 3">
            <div v-for="(leaderboard, index) in friendLeaderboard" :key="leaderboard.id">
              <div style="overflow: hidden;margin: 3% auto;">
                <div style="float: left;background-color: rgba(36, 76, 49, 1);width: 45px;height: 45px;border-radius: 30px;position: relative;margin-top: 8px;margin-left: 8px;">
                  <img :src="leaderboard.avatar" style="width: 100%;border-radius: 30px;background-color: white;" />
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -6px;top: -6px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle;font-size: 12px;">{{index + 1}}</span>
                  </span>
                </div>
                <div style="float: left;margin-left: 20px;margin-top: 12px;text-align: left;">
                  <span style="color: rgba(58, 62, 74, 1);font-size: 15px;font-weight: 600;">{{leaderboard.userName}}</span>
                  <br />
                  <span style="color: rgba(96, 103, 118, 1);font-size: 13px;">{{leaderboard.num}}</span>
                  <img src="@/assets/images/leaderboard003.png" style="width: 14px;vertical-align: middle;" />
                </div>
              </div>
            </div>
          </div>
          <el-button @click="$router.push('/leaderboard')" style="width: 100%;border-radius: 10px;background-color: rgba(92, 202, 155, 1);color: black;">{{$t('all.i_viewLeaderboard')}}</el-button>
        </div>

        <div style="margin: 4%;">
          <div style="font-size: 30px;text-align: left;">{{$t('all.i_task')}}</div>
          <div style="text-align: left;margin-bottom: 4%;color: rgba(109, 111, 117, 1);">{{$t('all.i_completeTasks')}}</div>

          <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
            <div v-for="(task, index) in taskList" :key="task.id">
              <div style="overflow: hidden;margin: 0;padding: 0;">
                <div style="float: left;height: 60px;">
                  <img @click="$router.push({ path: '/taskInfo', query: { tid: task.id } })" :src="task.appIcon" style="width: 60px;height: 60px;margin-right: 10px;border-radius: 12px;" />
                  <div @click="$router.push({ path: '/taskInfo', query: { tid: task.id } })" style="display: inline-block;vertical-align: top;">
                    <span v-html="task.title" style="font-size: 12px;font-weight: bold;float: left;margin-bottom: 6px;width: 160px;height: 34px;line-height: 18px;text-align: left;overflow: hidden;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;"></span>
                    <br />
                    <el-progress v-if="task.curTaskNum == 0" :show-text="false" :stroke-width="10" :percentage="0" :color="customColors" style="width: 50%;float: left;vertical-align: middle;margin-top: 4px;"></el-progress>
                    <el-progress v-else :show-text="false" :stroke-width="10" :percentage="(task.curTaskNum / task.totalTaskNum) * 100" :color="customColors" style="width: 50%;float: left;vertical-align: middle;margin-top: 4px;"></el-progress>
                    <span style="color: rgba(96, 103, 118, 1);font-size: 13px;vertical-align: middle;">
                      +{{task.rewardTotalCash }}
                    </span>
                    <img src="@/assets/common/cash1Icon.png" style="width: 14px;vertical-align: middle;" />
                  </div>
                </div>
                <div style="float: right;margin: 1px 0;">
                  <el-button v-if="task.maxCommission > 0 || task.commissionRate > 0" @click="$router.push({ path: '/inviteFriend', query: { tid: task.id } })" style="width: 100px;border-radius: 10px;background-color: rgba(246, 199, 69, 1);color: black;padding: 4px;margin-bottom: 5px;">{{$t('all.i_invite')}}</el-button>
                  <br />
                  <el-button @click="$router.push({ path: '/taskInfo', query: { tid: task.id } })" style="width: 100px;border-radius: 10px;background-color: rgba(92, 202, 155, 1);color: black;padding: 4px;margin-top: 5px;">{{$t('all.i_playNow')}}</el-button>
                </div>
              </div>
              <p v-if="(index + 1) != taskList.length" style="border: 1px solid rgba(190, 190, 190, 0.3);width: 90%;margin: 15px auto 18px auto;"></p>
            </div>
          </div>
        </div>
      </div>

      <!-- 联系我们 -->
      <div @click="contactDrawer = true" style="position: fixed;top: 40%;right: 2%;">
        <img src="@/assets/images/index/contactIcon.png" style="width: 40px;" />
      </div>

      <!-- 联系我们Drawer -->
      <el-drawer size="58%" direction="btt" :with-header="false" :show-close="false" :visible.sync="contactDrawer">
        <div>
          <div style="overflow: hidden;margin-top: 5%;margin-left: 5%;margin-right: 5%;">
            <span style="float: left;font-size: 20px;color: black;">{{ $t('index.i_contact') }}</span>
          </div>
          <div style="margin-top: 6%;">
            <img src="@/assets/images/index/goBrowser.png" style="width: 100px;" />
            <div style="margin-top: 2%;margin-bottom: 5%;color: rgba(127, 127, 149, 1);">{{ $t('index.i_browserOpen') }}</div>
          </div>
          <hr style="width: 80%;" />
          <div style="margin: auto;margin-top: 4%;margin-bottom: 6%;">
            <div>
              <span @click="goUrl(whatsApp)">
                <img src="@/assets/common/whatsApp.png" style="width: 50px;vertical-align: middle;" />
                <div style="display: inline-block;vertical-align: middle;margin-top: 6px;margin-left: 10px;margin-right: 10px;">
                  <p style="margin: 0;padding: 0;text-align: left;font-weight: bold;">WhatsApp</p>
                  <a :href="whatsApp" target="_blank" style="margin: 0;padding: 0;text-align: left;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;width: 150px;">{{ whatsApp }}</a>
                </div>
              </span>
              <el-button round type="primary" @click="copyVal(whatsApp)" style="width: 30%;background-color: rgba(0, 111, 255, 1);vertical-align: middle;">
                <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
                &nbsp;
                <span style="font-size: 16px;">Copy</span>
              </el-button>
            </div>
            <div style="margin: 4% auto;">
              <span @click="goUrl(meta)">
                <img src="@/assets/common/meta.png" style="width: 50px;vertical-align: middle;" />
                <div style="display: inline-block;vertical-align: middle;margin-top: 6px;margin-left: 10px;margin-right: 10px;">
                  <p style="margin: 0;padding: 0;text-align: left;font-weight: bold;">Meta</p>
                  <a :href="meta" target="_blank" style="margin: 0;padding: 0;text-align: left;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;width: 150px;">{{meta}}</a>
                </div>
              </span>
              <el-button round type="primary" @click="copyVal(meta)" style="width: 30%;background-color: rgba(0, 111, 255, 1);vertical-align: middle;">
                <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
                &nbsp;
                <span style="font-size: 16px;">Copy</span>
              </el-button>
            </div>
            <div>
              <span @click="goUrl(telegram)">
                <img src="@/assets/common/telegram.png" style="width: 50px;vertical-align: middle;" />
                <div style="display: inline-block;vertical-align: middle;margin-top: 6px;margin-left: 10px;margin-right: 10px;">
                  <p style="margin: 0;padding: 0;text-align: left;font-weight: bold;">Telegram</p>
                  <a :href="telegram" target="_blank" style="margin: 0;padding: 0;text-align: left;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;width: 150px;">{{ telegram }}</a>
                </div>
              </span>
              <el-button round type="primary" @click="copyVal(telegram)" style="width: 30%;background-color: rgba(0, 111, 255, 1);vertical-align: middle;">
                <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
                &nbsp;
                <span style="font-size: 16px;">Copy</span>
              </el-button>
            </div>
          </div>
        </div>
      </el-drawer>

      <!-- 账户中心 -->
      <el-dialog :title="$t('index.i_accountC')" :visible.sync="userInfoDialog" width="80%" center>
        <div style="width: 100%;text-align: center;background-color: rgba(244, 245, 247, 1);padding-top: 9%;padding-bottom: 8%;border-radius: 20px;">
          <div style="width: 60px;height: 60px;margin: auto;">
            <img src="@/assets/images/index/avatar.png" style="width: 60px;height: 60px;border-radius: 30px;" />
            <img :src="userInfo.avatar" style="width: 60px;height: 60px;border-radius: 30px;margin-left: -60px;" />
          </div>
          <table style="margin: auto;font-size: 18px;color: rgba(0, 0, 0, 1);margin-top: 10px;">
            <tr>
              <td style="text-align: right;">UID：</td>
              <td style="text-align: left;">{{ userInfo.uid }}</td>
            </tr>
            <tr>
              <td style="text-align: right;">{{$t('all.i_nickName')}}：</td>
              <td style="text-align: left;">{{ userInfo.userName }}</td>
            </tr>
            <tr v-if="userInfo.inviteCode != null">
              <td style="text-align: right;">{{ $t('index.i_inviteCode') }}：</td>
              <td style="text-align: left;">
                <span @click="copyVal(userInfo.inviteCode)">{{ userInfo.inviteCode }}</span>
                <span style="margin-left: 4px;" @click="copyVal(userInfo.inviteCode)">
                  <img src="@/assets/common/copyIconB.png" style="margin-bottom: 10px;width: 10px;" />
                </span>
              </td>
            </tr>
          </table>
        </div>
      </el-dialog>

      <!-- 错误信息Dialog -->
      <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnPressEscape="false">
        <div style="text-align: center;margin-bottom: 10%;">
          <div style="font-size: 16px;color: #000;">{{ failMessage }}</div>
        </div>
        <div style="text-align: center;">
          <el-button round type="primary" @click="go()" style="width: 60%;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      userInfo: {
        uid: 0,
        avatar: "",
        cashNum: 0,
        inviteCode: "",
        appName: "",
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      failMessage: null,
      checkDialog: false,
      userInfoDialog: false,
      contactDrawer: false,
      whatsApp: "https://chat.whatsapp.com/BsP9LYv73k2E6NpjphgAUf",
      meta: "https://www.facebook.com/profile.php?id=61554439329069",
      telegram: "https://t.me/+RleP9gx8ThFjNDM1",
      contact: "https://www.facebook.com/people/Puzzle-Story/100089158787963/",
      leaderboardType: 1,
      customColors: "rgba(237, 179, 77, 1)",
      taskList: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      todayCashLeaderboard: [],
      cashLeaderboard: [],
      friendLeaderboard: []
    };
  },
  methods: {
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },
    getIndex() {
      this.isLoading = true;
      this.$axios({
        url: "/webCash/index",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.userInfo = result.data.data.userInfo;
          // this.failMessage = result.data.data.failMessage;
          this.$store.state.cashNum = result.data.data.userInfo.cashNum;
          this.todayCashLeaderboard =
            result.data.data.leaderboard.todayCashLeaderboard;
          if (result.data.data.leaderboard.todayCashLeaderboard == null) {
            this.leaderboardType = 2;
          }
          this.cashLeaderboard = result.data.data.leaderboard.cashLeaderboard;
          this.friendLeaderboard =
            result.data.data.leaderboard.friendLeaderboard;
          if (result.data.data.userInfo != null) {
            sessionStorage.setItem(
              "userInfo",
              JSON.stringify(result.data.data.userInfo)
            );
          } else {
            sessionStorage.removeItem("userInfo");
          }
        }
      });
    },
    goApp() {
      window.location.href = this.userInfo.appScheme;
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    goUrl(url) {
      window.open(url, "_blank");
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.busy = true;
        this.pageNum++;
        this.isLoading = true;
        this.$axios({
          url: "/webCash/taskList",
          method: "get",
          params: {
            pageNum: this.pageNum
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            result.data.data.taskList.forEach((element) => {
              this.taskList.push(element);
            });
            this.isLast = result.data.data.isLast;
          }
        });
        this.busy = false;
        if (this.failMessage != null) {
          this.checkDialog = true;
        }
      }
    }
  },
  created() {
    // if (this.$route.query.token != null) {
    //   localStorage.setItem("token", this.$route.query.token);
    // }
    if (this.$route.query.tid != null && this.$route.query.referrer != null) {
      this.$router.push({
        path: "/taskInfo",
        query: {
          tid: this.$route.query.tid,
          referrer: this.$route.query.referrer
        }
      });
    }
    this.getIndex();
  }
};
</script>

<style>
#index-layout {
  max-width: 500px;
  margin: auto;
}

#index-layout .index-top {
  padding-bottom: 2%;
}

#index-layout .i-page-header {
  overflow: hidden;
  padding: 4% 4% 0 4%;
}

#index-layout .el-page-header__left::after {
  display: none;
}

#index-layout .i-gift-code-btn,
#index-layout .bottom-menu-bar .el-button {
  border: none;
  background: none;
}

#index-layout .el-drawer {
  border-radius: 20px 20px 0 0 !important;
}

#index-layout .leaderboardAct {
  background-color: rgba(32, 35, 42, 1);
  color: white;
  border-radius: 30px;
}
</style>
