<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="invite-friend-layout">
      <div class="invite-friend-top">
        <!-- 导航条 -->
        <div class="if-page-header">
          <img src="@/assets/common/leftArrow.png" @click="$router.push('/index')" style="float: left;width: 24px;margin-top: 12px;margin-right: 10px;margin-bottom: 4px;" />
          <div style="float: right;">
            <div style="float: right;background-color: rgba(251, 236, 143, 1);padding: 3px 7px 4px 7px;border-radius: 20px;margin: 6px 8px 0 0;">
              <span style="font-size: 14px;color: black;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(87, 36, 13, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
            </div>
          </div>
        </div>

        <p style="border: 1px solid rgba(240, 240, 240, 1);padding: 0;margin: 3% 0;"></p>

        <div style="margin-left: 4%;margin-right: 4%;margin-bottom: 6%;">
          <div style="background-color: rgba(223, 227, 252, 1);padding: 4% 4%;border-radius: 15px;margin-top: 2%;">
            <div style="position: relative;">
              <span style="z-index: 1;position: absolute;box-sizing: border-box;top: 24%;left: 0;right: 0;font-size: 12px;">
                <img src="@/assets/images/invite/inviteTopBg.png" style="width: 100%;" />
              </span>
              <div style="position: relative;z-index: 2;">
                <div>{{$t('all.i_currentTeam')}}</div>
                <div style="margin-top: 8%;margin-bottom: 10%;">
                  <div style="width: 60px;height: 60px;margin: auto;">
                    <img :src="inviteInfo.appIcon" style="width: 60px;height: 60px;vertical-align: middle;border-radius: 10px;" />
                  </div>
                  <div style="margin-top: 2%;">“{{ inviteInfo.appName }}”</div>
                </div>
                <div style="color: rgba(152, 157, 187, 1);font-size: 12px;">{{$t('all.i_invitationCode')}}</div>
                <div>
                  <span class="invite-code-text">
                    <span @click="copyVal(userInfo.inviteCode)" style="color: black;">{{ userInfo.inviteCode }}</span>
                    <span @click="copyVal(userInfo.inviteCode)" style="margin-left: 2%;">
                      <img src="@/assets/common/copyIconB.png" style="width: 10px;margin-bottom: 8px;opacity: 0.3;" />
                    </span>
                  </span>
                </div>
                <el-button @click="inviteLinkDrawer = true" style="width: 80%;border-radius: 15px;background-color: rgba(68, 81, 239, 1);color: white;margin-top: 5%;">
                  {{$t('all.i_inviteFriendsJoinTeam')}}
                </el-button>
              </div>
            </div>
          </div>
        </div>

        <div style="margin-left: 4%;margin-right: 4%;margin-bottom: 6%;">
          <div style="background-color: rgba(253, 251, 219, 1);padding: 4% 4% 4% 4%;border-radius: 15px;margin-top: 2%;color: rgba(75, 32, 12, 1);">
            <div style="font-size: 20px;">{{$t('all.i_everyFriendInvited')}}：</div>
            <div style="margin: 4% auto;">
              <div style="background-color: rgba(239, 187, 82, 1);display: inline-block;border-radius: 10px;padding: 3%;width: 50px;height: 56px;vertical-align: top;">
                <img src="@/assets/common/cashIcon.png" style="width: 30px;vertical-align: middle;margin-bottom: 10px;" />
                <br />
                <span style="">{{ inviteInfo.commissionRate }}%</span>
              </div>
              <div style="margin: 0 6%;display: inline-block;"></div>
              <div style="background-color: rgba(239, 187, 82, 1);display: inline-block;border-radius: 10px;padding: 3%;width: 50px;height: 56px;vertical-align: top;">
                <img src="@/assets/common/cashIcon.png" style="width: 30px;vertical-align: middle;" />
                <br />
                <div style="display: inline-block;text-align: left;margin-top: -4px;">
                  <span style="font-size: 8px;">Up to</span>
                  <br />
                  <span>{{ inviteInfo.maxCommission }}</span>
                </div>
              </div>
            </div>
            <div style="text-align: left;">
              <div>
                {{$t('all.i_currentFriends')}}：
                <span style="font-weight: bold;">{{inviteNum.inviteNum}}</span>
                <img src="@/assets/images/leaderboard003.png" style="width: 18px;vertical-align: middle;" />
              </div>
              <div style="margin-top: 10px;">
                {{$t('all.i_currentRewards')}}：
                <span style="font-weight: bold;">{{inviteNum.totalCashNum}}</span>
                <img src="@/assets/common/cash1Icon.png" style="width: 18px;vertical-align: middle;" />
              </div>
              <div style="margin: 3% auto;">
                {{$t('all.i_friendsRewardsThem')}} {{ inviteInfo.commissionRate }}% {{$t('all.i_commissionUpTo')}} {{ inviteInfo.maxCommission }}
              </div>
              <div style="line-height: 30px;font-size: 15px;">
                ①{{$t('all.i_friendsClickJoinTask')}}
                <br />
                ②{{$t('all.i_friendsCompleteInitialTask')}}
                <br />
                ③{{$t('all.i_joinTeam')}}
              </div>
            </div>
          </div>
        </div>

        <div style="margin: auto 4%;">
          <div style="overflow: hidden;margin-bottom: 3%;">
            <div style="float: left;">
              <span :class="inviteLogType == 1 ? 'inviteLogAct' : ''" @click="inviteLogType = 1;loadMoreOne();" style="color: rgba(172, 174, 184, 1);font-weight: bold;margin-right: 20px;">{{$t('all.i_newestFriends')}}</span>
              <span :class="inviteLogType == 0 ? 'inviteLogAct' : ''" @click="inviteLogType = 0;loadMore();" style="color: rgba(172, 174, 184, 1);font-weight: bold;">{{$t('all.i_bestFriends')}}</span>
            </div>
            <div style="float: right;">
              <span>{{$t('all.i_commission')}}</span>
            </div>
          </div>
          <div v-infinite-scroll="loadMoreOne" infinite-scroll-disabled="busyOne" infinite-scroll-distance="10">
            <div v-if="inviteLogType == 1">
              <div v-for="log in inviteLogOne" :key="log.id">
                <div style="overflow: hidden;margin-bottom: 3%;">
                  <div style="float: left;">
                    <img :src="log.beInviteAvatar" style="width: 40px;margin-right: 10px;border-radius: 30px;" />
                    <div style="display: inline-block;vertical-align: top;text-align: left;">
                      <span style="font-size: 12px;font-weight: bold;">
                        {{log.beInviteUserName}}
                      </span>
                      <br />
                      <span style="color: rgba(102, 109, 113, 1);font-size: 12px;">
                        {{log.inviteTime}}
                      </span>
                    </div>
                  </div>
                  <div style="float: right;margin-top: 10px;">
                    <span style="color: rgba(96, 103, 118, 1);vertical-align: middle;font-weight: bold;">+{{log.reward}}</span>
                    <img src="@/assets/common/cash1Icon.png" style="width: 14px;vertical-align: middle;" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="inviteLogType == 1 && inviteLogOne == ''">
            <img src="@/assets/common/noIcon.png" style="margin-top: 10%;margin-bottom: 10%;width: 150px;" />
          </div>
          <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
            <div v-if="inviteLogType == 0">
              <div v-for="log in inviteLog" :key="log.id">
                <div style="overflow: hidden;margin-bottom: 3%;">
                  <div style="float: left;">
                    <img :src="log.beInviteAvatar" style="width: 40px;margin-right: 10px;border-radius: 30px;" />
                    <div style="display: inline-block;vertical-align: top;text-align: left;">
                      <span style="font-size: 12px;font-weight: bold;">
                        {{log.beInviteUserName}}
                      </span>
                      <br />
                      <span style="color: rgba(102, 109, 113, 1);font-size: 12px;">
                        {{log.inviteTime}}
                      </span>
                    </div>
                  </div>
                  <div style="float: right;margin-top: 10px;">
                    <span style="color: rgba(96, 103, 118, 1);vertical-align: middle;font-weight: bold;">+{{log.reward}}</span>
                    <img src="@/assets/common/cash1Icon.png" style="width: 14px;vertical-align: middle;" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="inviteLogType == 0 && inviteLog == ''">
            <img src="@/assets/common/noIcon.png" style="margin-top: 10%;margin-bottom: 10%;width: 150px;" />
          </div>
        </div>
      </div>

      <el-drawer size="40%" direction="btt" :with-header="false" :show-close="false" :visible.sync="inviteLinkDrawer">
        <div>
          <div style="overflow: hidden;margin-top: 5%;margin-left: 5%;margin-right: 5%;">
            <img :src="inviteInfo.appIcon" style="float: left;height: 50px;border-radius: 10px;" />
            <span style="float: left;margin-top: 5%;margin-left: 5%;font-weight: bold;color: black;">
              {{ inviteInfo.appName }}
            </span>
          </div>
          <div style="background-color: rgba(223, 223, 227, 1);margin: 5%;padding: 3%;border-radius: 6px;">
            <span style="">
              <a :href="inviteLink" style="word-break: normal;overflow-wrap: break-word;">{{ inviteLink }}</a>
            </span>
          </div>
          <div>
            <el-button round type="primary" @click="copyVal(inviteLink)" style="width: 38%;background-color: rgba(0, 111, 255, 1);">
              <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
              &nbsp;
              <span style="font-size: 16px;">Copy</span>
            </el-button>
          </div>
        </div>
      </el-drawer>

      <!-- 初始任务状态Dialog -->
      <el-dialog :title="$t('convert.i_tips')" :visible.sync="initTaskStatusDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
        <div style="text-align: center;margin-bottom: 10%;">
          <div style="font-size: 16px;color: #000;word-break: normal;overflow-wrap: break-word;">{{ $t('all.i_pleaseInitTask') }}</div>
        </div>
        <div style="text-align: center;">
          <el-button round type="primary" @click="goTaskInfo()" style="width: 60%;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>

      <!-- 错误信息Dialog -->
      <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnPressEscape="false">
        <div style="text-align: center;margin-bottom: 10%;">
          <div style="font-size: 16px;color: #000;">{{ failMessage }}</div>
        </div>
        <div style="text-align: center;">
          <el-button round type="primary" @click="go()" style="width: 60%;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      userInfo: {
        uid: 0,
        avatar: "",
        cashNum: 0,
        inviteCode: ""
      },
      failMessage: null,
      checkDialog: false,
      inviteInfo: {},
      inviteNum: {},
      inviteLinkDrawer: false,
      inviteLink: "",
      inviteLogType: 1,
      inviteLogOne: [],
      busyOne: false,
      pageNumOne: 0,
      isLastOne: false,
      inviteLog: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      initTaskStatusDialog: false
    };
  },
  methods: {
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },
    getInviteInfo() {
      this.isLoading = true;
      this.$axios({
        url: "/webCash/inviteFriend",
        method: "get",
        params: {
          tid: this.$route.query.tid
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.userInfo = result.data.data.userInfo;
          this.$store.state.cashNum = result.data.data.userInfo.cashNum;
          this.inviteInfo = result.data.data.inviteInfo;
          this.inviteNum = result.data.data.inviteNum;
          this.inviteLink =
            window.location.origin +
            "/#/invite?tid=" +
            this.$route.query.tid +
            "&referrer=" +
            result.data.data.userInfo.inviteCode;
          if (result.data.data.initTaskStatus == false) {
            this.initTaskStatusDialog = true;
          }
        } else if (result.data.code == 0) {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
          this.$router.push("/index");
        }
      });
    },
    goApp() {
      window.location.href = this.userInfo.appScheme;
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    goTaskInfo() {
      this.$router.push({
        path: "/taskInfo",
        query: {
          tid: this.$route.query.tid,
          referrer: this.$route.query.referrer
        }
      });
      this.initTaskStatusDialog = false;
    },
    loadMoreOne: function () {
      if (this.isLastOne != true && this.inviteLogType == 1) {
        this.busyOne = true;
        this.pageNumOne++;

        this.isLoading = true;
        this.$axios({
          url: "/webCash/inviteLog",
          method: "get",
          params: {
            tid: this.$route.query.tid,
            pageNum: this.pageNumOne,
            status: 1
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            result.data.data.inviteLog.forEach((element) => {
              this.inviteLogOne.push(element);
            });
            this.isLastOne = result.data.data.isLast;
          }
        });

        this.busyOne = false;
      }
    },
    loadMore: function () {
      if (this.isLast != true && this.inviteLogType == 0) {
        this.busy = true;
        this.pageNum++;

        this.isLoading = true;
        this.$axios({
          url: "/webCash/inviteLog",
          method: "get",
          params: {
            tid: this.$route.query.tid,
            pageNum: this.pageNum,
            status: 0
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            result.data.data.inviteLog.forEach((element) => {
              this.inviteLog.push(element);
            });
            this.isLast = result.data.data.isLast;
          }
        });
        this.busy = false;
      }
    }
  },
  created() {
    this.getInviteInfo();
  }
};
</script>

<style>
#invite-friend-layout {
  max-width: 500px;
  margin: auto;
}

#invite-friend-layout .invite-friend-top {
  padding-bottom: 2%;
}

#invite-friend-layout .if-page-header {
  overflow: hidden;
  padding: 4% 4% 0 4%;
}

#invite-friend-layout .el-page-header__left::after {
  display: none;
}

#invite-friend-layout .i-gift-code-btn,
#invite-friend-layout .bottom-menu-bar .el-button {
  border: none;
  background: none;
}

#invite-friend-layout .el-drawer {
  border-radius: 20px 20px 0 0 !important;
}

#invite-friend-layout .inviteLogAct {
  color: rgba(32, 35, 42, 1) !important;
}
</style>
