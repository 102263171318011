import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
// 引入各个语言配置文件
import zh from "@/i18n/lang/zh";
import en from "@/i18n/lang/en";

// 获取语言
let language = navigator.language;

if (
  language == "zh-CN" ||
  language == "zh-TW" ||
  language == "zh-HK" ||
  language == "zh-MO" ||
  language == "zh-SG" ||
  language == "zh" ||
  language == "en-US" ||
  language == "en"
) {
} else {
  language = "en";
}

// 创建vue-i18n实例i18n
const i18n = new VueI18n({
  // 设置默认语言
  locale: language,
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    "zh-CN": zh,
    "en-US": en,
    "zh-TW": zh,
    "zh-HK": zh,
    "zh-MO": zh,
    "zh-SG": zh,
    zh: zh,
    en: en
  }
});
// 暴露i18n
export default i18n;
