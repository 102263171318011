<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="login-layout">
      <div style="padding-top: 90px;padding-bottom: 30px;">
        <img src="@/assets/common/bejoyitIcon.png" style="width: 30%;" />
        <div style="font-size: 20px;font-weight: bold;">{{$t('all.i_playGamesCash')}}</div>
        <div style="margin-top: 60px;margin-bottom: 30px;">
          <div>{{$t('all.i_joinNow')}}</div>
          <div>Be joy it</div>
        </div>
        <div style="color: rgba(189, 194, 204, 1);margin-bottom: 2px;font-size: 15px;">Google Sign-in</div>
        <!-- <div style="width: 200px;text-align: center;margin: auto;"> -->
        <div id="g_id_onload" data-client_id="14636247541-pf422r0n5hdq2ql9gehnp1jc67akucpt.apps.googleusercontent.com" data-context="signin" data-ux_mode="popup" data-callback="handleCredentialResponse" data-nonce="" data-itp_support="true">
        </div>

        <div class="g_id_signin" data-type="standard" data-shape="pill" data-theme="outline" data-text="signin_with" data-size="large" data-logo_alignment="left">
        </div>
        <!-- </div> -->
        <div style="margin-top: 10%;color: rgba(168, 174, 186, 1);margin-bottom: 6px;font-size: 12px;">
          ({{$t('all.i_useBrowser')}})
        </div>
        <div>
          <img src="@/assets/images/loginBrowser.png" style="width: 40%;" />
        </div>
      </div>
    </div>

    <el-drawer size="40%" direction="btt" :with-header="false" :wrapper-closable="false" :visible.sync="checkBrowserDrawer">
      <div>
        <div style="overflow: hidden;margin-top: 5%;margin-left: 5%;margin-right: 5%;">
          <span style="float: left;font-size: 20px;color: black;vertical-align: middle;">{{ $t('convert.i_tips') }}</span>
          <img src="@/assets/common/cX.png" @click="checkBrowserDrawer = false" style="float: right;width: 30px;vertical-align: middle;" />
        </div>
        <div style="margin-top: 6%;">
          <img src="@/assets/images/copyToBrowser.png" style="width: 240px;" />
          <div style="margin-top: 2%;margin-bottom: 5%;color: rgba(127, 127, 149, 1);">{{ $t('all.i_copyToBrowser') }}</div>
        </div>
        <hr style="width: 80%;" />
        <div style="margin: auto;margin-top: 4%;margin-bottom: 6%;">
          <div>
            <el-button round type="primary" @click="copyCurUrl()" style="width: 30%;background-color: rgba(0, 111, 255, 1);vertical-align: middle;">
              <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
              &nbsp;
              <span style="font-size: 16px;">Copy</span>
            </el-button>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      checkBrowserDrawer: false
    };
  },
  mounted() {
    let script = document.createElement("script"); //创建script标签
    script.type = "text/javascript";
    script.src = "https://accounts.google.com/gsi/client"; //外部js文件地址
    document.body.appendChild(script); //外部链接需要调用（本地不用）
    this.$nextTick(() => {
      setTimeout(() => {
        if (
          document.getElementsByClassName("g_id_signin")[0].childElementCount ==
          0
        ) {
          this.checkBrowserDrawer = true;
        }
      }, 1200);
    });
  },
  methods: {
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },
    copyCurUrl() {
      this.copyVal(window.location.href);
    }
  },
  created() {}
};
</script>

<style>
#login-layout {
  max-width: 500px;
  margin: auto;
}
#login-layout .g_id_signin div[role="button"] {
  width: 220px !important;
  margin: auto !important;
}
#login-layout .g_id_signin iframe {
  display: inline !important;
}
</style>
