<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="task-info-layout">
      <div class="index-top">
        <!-- 导航条 -->
        <div class="i-page-header">
          <img src="@/assets/common/leftArrow.png" @click="$router.push('/index')" style="float: left;width: 24px;margin-top: 10px;margin-right: 10px;" />
          <img src="@/assets/common/bejoyitIcon.png" @click="$router.push('/index')" style="float: left;width: 36px;" />
          <div style="float: right;">
            <div style="float: right;background-color: rgba(251, 236, 143, 1);margin: 6px 8px 0 0;padding: 3px 7px 4px 7px;border-radius: 20px;">
              <span style="font-size: 14px;color: black;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(87, 36, 13, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
            </div>
          </div>
        </div>

        <p style="border: 1px solid rgba(240, 240, 240, 1);margin: 3% 0;padding: 0;"></p>

        <div style="margin-left: 4%;margin-right: 4%;margin-bottom: 6%;">
          <div style="overflow: hidden;">
            <div style="float: left;font-size: 20px;">
              {{$t('all.i_initialTask')}}
              <img src="@/assets/common/personRenmenIcon.png" style="width: 14px;vertical-align: middle;" />
              <span style="font-size: 14px;color: rgba(159, 199, 185, 1);margin-left: 2px;vertical-align: middle;"> {{ taskInfo.clickNum }} {{$t('all.i_joined')}}</span>
            </div>
            <div v-if="taskInfo.testStatus" style="float: right;">
              <img src="@/assets/common/testIcon.png" style="width: 22px;vertical-align: middle;margin-top: 4px;" />
            </div>
          </div>
          <div style="background-color: rgba(235, 252, 246, 1);padding: 4% 4%;border-radius: 15px;margin-top: 2%;">

            <div style="overflow: hidden;text-align: left;">
              <div>①{{$t('all.i_download')}}“{{ taskInfo.appName }}”</div>
              <div style="margin: 4% 0 4% 0;">
                <div style="float: left;width: 44px;height: 44px;display: inline-block;vertical-align: middle;margin-right: 10px;">
                  <img :src="taskInfo.appIcon" style="width: 44px;height: 44px;border-radius: 10px;" />
                </div>
                <el-button @click="goUrl(taskInfo.downloadLink)" style="border-radius: 30px;background-color: rgba(0, 0, 0, 1);color: white;vertical-align: middle;margin: 2px auto;margin-right: 6px;">{{$t('all.i_downloadNo')}}</el-button>
                <img v-if="taskInfo.platformIcon == 1 || taskInfo.platformIcon == 0" src="@/assets/common/iosIcon.png" style="width: 20px;height: 20px;vertical-align: middle;margin: auto 5px;" />
                <img v-if="taskInfo.platformIcon == 2 || taskInfo.platformIcon == 0" src="@/assets/common/androidIcon.png" style="width: 20px;height: 20px;vertical-align: middle;margin: auto 5px;" />
              </div>
              <div v-if="initTask != null">
                <div>
                  ②{{$t('all.i_loginAccount1')}}
                  <br/>
                  <span style="font-weight: bold;">
                    “<img src="@/assets/common/googleIcon.png" style="width: 20px;height: 20px;vertical-align: middle;margin: auto 5px;" />{{$t('all.i_loginAccount2')}}”
                  </span>
                  {{$t('all.i_loginAccount3')}}
                </div>
                <div style="margin: 4% 0 4% 0;">
                  <span>{{$t('all.i_state')}}：</span>
                  <span v-if="gameInfo.loginStatus == true" style="color: rgba(62, 66, 75, 1);">{{$t('all.i_completed')}}</span>
                  <span v-else style="color: rgba(255, 54, 0, 1);">{{$t('all.i_undone')}}</span>
                  <img v-if="gameInfo.loginStatus == true" src="@/assets/common/yesIcon.png" style="width: 30px;margin-left: 10px;vertical-align: middle;" />
                  <el-button v-else @click="goApp(taskInfo.appSchemeLogin)" style="border-radius: 30px;background-color: rgba(0, 0, 0, 1);color: white;margin-left: 10px;">Go</el-button>
                </div>
                <div v-html="initTask.title"></div>
                <div style="margin: 4% 0 4% 0;">
                  <span v-if="initTask.ok == false && gameInfo.turntableNum < initTask.conditionNum" style="font-size: 24px;vertical-align: middle;">
                    {{ gameInfo.turntableNum }}/{{ initTask.conditionNum }}
                  </span>
                  <span v-else style="font-size: 24px;vertical-align: middle;">
                    {{ initTask.conditionNum }}/{{ initTask.conditionNum }}
                  </span>
                  <img v-if="initTask.ok == true || gameInfo.turntableNum >= initTask.conditionNum" src="@/assets/common/yesIcon.png" style="width: 30px;margin-left: 10px;vertical-align: middle;" />
                  <el-button v-else @click="goApp(taskInfo.appSchemeTurntable)" style="border-radius: 30px;background-color: rgba(0, 0, 0, 1);color: white;margin-left: 10px;">Go</el-button>
                </div>
                <el-button v-if="initTask.ok == false && gameInfo.turntableNum < initTask.conditionNum" style="width: 100%;border-radius: 15px;background-color: rgba(119, 208, 160, 1);color: black;margin-top: 5%;height: 68px;">
                  <span style="font-size: 24px;color: black;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(87, 36, 13, 1);font-weight: 600;">
                    +{{initTask.rewardTotalCash }}
                  </span>
                  <img src="@/assets/common/cash1Icon.png" style="width: 22px;vertical-align: middle;" />
                  <br />
                  <span>({{$t('all.i_undone')}})</span>
                </el-button>
                <el-button @click="getTaskReward(initTask.id)" v-if="initTask.ok == false && gameInfo.turntableNum >= initTask.conditionNum" style="width: 100%;border-radius: 15px;background-color: rgba(247, 198, 69, 1);color: black;margin-top: 5%;height: 68px;">
                  <span style="font-size: 24px;color: black;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(87, 36, 13, 1);font-weight: 600;">
                    +{{ initTask.rewardTotalCash }}
                  </span>
                  <img src="@/assets/common/cash1Icon.png" style="width: 22px;vertical-align: middle;" />
                  <br />
                  <span>({{$t('all.i_claimRewards')}})</span>
                </el-button>
                <el-button v-if="initTask.ok == true" style="width: 100%;border-radius: 15px;background-color: rgba(213, 242, 232, 1);color: black;margin-top: 5%;height: 68px;">
                  <span>({{$t('all.i_completed')}})</span>
                </el-button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="expTaskList != null" style="margin-left: 4%;margin-right: 4%;margin-bottom: 6%;">
          <div style="text-align: left;font-size: 20px;">{{$t('all.i_expTsaks')}}</div>
          <div style="background-color: rgba(57, 126, 98, 1);padding: 5%;border-radius: 15px;margin-top: 2%;">
            <div v-for="(task, index) in expTaskList" :key="task.id">
              <div style="overflow: hidden;">
                <div style="float: left;">
                  <div style="width: 60px;height: 60px;display: inline-block;margin-right: 10px;">
                    <img :src="taskInfo.appIcon" style="width: 60px;height: 60px;border-radius: 10px;" />
                  </div>
                  <div style="display: inline-block;vertical-align: top;color: white;text-align: left;">
                    <span style="font-size: 12px;vertical-align: middle;width: 120px;height: 32px;overflow: hidden;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;margin-bottom: -6px;">
                      {{ task.title }}
                    </span>
                    <br />
                    <span style="vertical-align: middle;font-size: 14px;">{{ gameInfo.expNum }}/{{ task.conditionNum }}</span>
                  </div>
                  <el-progress :show-text="false" :stroke-width="14" :percentage="((gameInfo.expNum / task.conditionNum) * 100) >= 100 ? 100 : ((gameInfo.expNum / task.conditionNum) * 100)" :color="customColors" style="width: 100%;float: left;vertical-align: middle;margin-top: 4px;"></el-progress>
                </div>
                <div style="float: right;">
                  <div>
                    <span style="color: rgba(247, 198, 69, 1);font-size: 16px;vertical-align: middle;font-weight: bold;">
                      +{{task.rewardCash}}
                    </span>
                    <img src="@/assets/common/cash1Icon.png" style="width: 15px;vertical-align: middle;" />
                  </div>
                  <el-button v-if="gameInfo.expNum < task.conditionNum" @click="goApp(taskInfo.appScheme)" round style="width: 100px;background-color: rgba(119, 208, 160, 1);color: black;margin-top: 23px;">Go</el-button>
                  <el-button v-else @click="getTaskReward(task.id)" round style="width: 100px;background-color: rgba(246, 199, 69, 1);color: black;margin-top: 23px;">{{$t('all.i_claim')}}</el-button>
                </div>
              </div>
              <div v-if="(index + 1) != expTaskList.length" style="height: 30px;"></div>
            </div>
            <div v-if="expTaskList.length <= 0">
              <img src="@/assets/images/invite/yesIcon.png" style="width: 20px;vertical-align: middle;margin-bottom: 10px;" />
              <div style="color: rgba(94, 172, 140, 1);">{{$t('all.i_allTasksCompleted')}}</div>
            </div>
          </div>
        </div>

        <div style="margin-left: 4%;margin-right: 4%;margin-bottom: 6%;">
          <div style="text-align: left;font-size: 20px;">{{$t('all.i_advancedTasks')}}</div>
          <div style="background-color: rgba(57, 126, 98, 1);padding: 5%;border-radius: 15px;margin-top: 2%;">
            <div v-for="(task, index) in advanceTaskList" :key="task.id">
              <div style="overflow: hidden;">
                <div style="float: left;">
                  <div style="width: 60px;height: 60px;display: inline-block;margin-right: 10px;">
                    <img :src="taskInfo.appIcon" style="width: 60px;height: 60px;border-radius: 10px;" />
                  </div>
                  <div style="display: inline-block;vertical-align: top;color: white;text-align: left;">
                    <span style="font-size: 12px;vertical-align: middle;width: 120px;height: 32px;overflow: hidden;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;margin-bottom: -6px;">
                      {{ task.title }}
                    </span>
                    <br />
                    <span style="vertical-align: middle;font-size: 14px;">{{ gameInfo.goldNum }}/{{ task.conditionNum }}</span>
                    <img src="@/assets/common/goldIcon.png" style="width: 12px;vertical-align: middle;margin-left: 3%;" />
                  </div>
                  <el-progress :show-text="false" :stroke-width="14" :percentage="((gameInfo.goldNum / task.conditionNum) * 100) >= 100 ? 100 : ((gameInfo.goldNum / task.conditionNum) * 100)" :color="customColors" style="width: 100%;float: left;vertical-align: middle;margin-top: 4px;"></el-progress>
                </div>
                <div style="float: right;">
                  <div>
                    <span style="color: rgba(247, 198, 69, 1);font-size: 16px;vertical-align: middle;font-weight: bold;">
                      +{{task.rewardCash}}
                    </span>
                    <img src="@/assets/common/cash1Icon.png" style="width: 15px;vertical-align: middle;" />
                  </div>
                  <el-button v-if="gameInfo.goldNum < task.conditionNum" @click="goApp(taskInfo.appScheme)" round style="width: 100px;background-color: rgba(119, 208, 160, 1);color: black;margin-top: 23px;">Go</el-button>
                  <el-button v-else @click="getTaskReward(task.id)" round style="width: 100px;background-color: rgba(246, 199, 69, 1);color: black;margin-top: 23px;">{{$t('all.i_claim')}}</el-button>
                </div>
              </div>
              <div v-if="(index + 1) != advanceTaskList.length" style="height: 30px;"></div>
            </div>
            <div v-if="advanceTaskList.length <= 0">
              <img src="@/assets/images/invite/yesIcon.png" style="width: 20px;vertical-align: middle;margin-bottom: 10px;" />
              <div style="color: rgba(94, 172, 140, 1);">{{$t('all.i_allTasksCompleted')}}</div>
            </div>
          </div>
        </div>

        <div v-if="taskInfo.maxCommission > 0 || taskInfo.commissionRate > 0" style="margin-left: 4%;margin-right: 4%;margin-bottom: 6%;">
          <div style="text-align: left;font-size: 20px;">{{$t('all.i_inviteTasks')}}</div>
          <div style="background-color: rgba(254, 251, 210, 1);padding: 4% 4%;border-radius: 15px;margin-top: 2%;">
            <div style="">
              <div style="color: rgba(94, 43, 12, 1);margin-top: 2%;margin-bottom: 10%;">{{$t('all.i_inviteFriendsJoin')}}</div>
              <div style="width: 60px;height: 60px;margin: auto;">
                <img :src="taskInfo.appIcon" style="width: 60px;height: 60px;border-radius: 10px;" />
              </div>
              <div style="color: rgba(94, 43, 12, 1);margin-top: 2%;">“{{ taskInfo.appName }}”</div>
              <div style="margin-top: 4%;">
                <div style="display: inline-block;text-align: left;">
                  <el-button round style="background-color: rgba(94, 43, 12, 1);color: rgba(254, 251, 210, 1);padding: 0 4px;">Up to</el-button>
                  <br />
                  <span style="color: rgba(94, 43, 12, 1);font-size: 22px;vertical-align: middle;font-weight: bold;">{{taskInfo.maxCommission}}</span>
                  <img src="@/assets/common/cash1Icon.png" style="width: 18px;vertical-align: middle;" />
                </div>
                <span style="color: rgba(94, 43, 12, 1);font-size: 20px;vertical-align: middle;font-weight: bold;">/{{$t('all.i_friends')}}</span>
              </div>
              <el-button @click="$router.push({ path: '/inviteFriend', query: { tid: $route.query.tid } })" style="width: 80%;border-radius: 10px;background-color: rgba(244, 182, 63, 1);color: black;margin-top: 8%;">{{$t('all.i_inviteFriendsTeam')}}</el-button>
            </div>
          </div>
        </div>
      </div>

      <!-- 兑换中心 -->
      <div @click="$router.push('/index')" style="position: fixed;top: 40%;right: 2%;">
        <img src="@/assets/images/task/convertIcon.png" style="width: 40px;" />
      </div>

      <el-dialog :title="$t('all.i_earnRewards')" :visible.sync="rewardDialog" width="70%" center :closeOnPressEscape="false">
        <div style="text-align: center;margin-bottom: 10%;background-color: rgba(244, 245, 247, 1);padding: 6%;border-radius: 10px;">
          <span style="font-size: 24px;color: #000;vertical-align: middle;">{{rewardNum}}</span>
          <img src="@/assets/common/cash1Icon.png" style="width: 18px;vertical-align: middle;margin-left: 4px;" />
        </div>
        <div style="text-align: center;">
          <el-button type="primary" @click="rewardDialog = false" style="width: 80%;background-color: rgba(247, 198, 69, 1);color: black;border: none;border-radius: 15px;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>

      <!-- 错误信息Dialog -->
      <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnPressEscape="false">
        <div style="text-align: center;margin-bottom: 10%;">
          <div style="font-size: 16px;color: #000;">{{ failMessage }}</div>
        </div>
        <div style="text-align: center;">
          <el-button round type="primary" @click="go()" style="width: 60%;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      userInfo: {
        uid: 0,
        cashNum: 0,
        inviteCode: "",
        appName: "",
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      failMessage: null,
      checkDialog: false,
      customColors: "rgba(237, 179, 77, 1)",
      taskInfo: {},
      initTask: {},
      advanceTaskList: [],
      expTaskList: [],
      gameInfo: {},
      rewardDialog: false,
      rewardNum: ""
    };
  },
  methods: {
    getTaskInfo() {
      this.isLoading = true;
      this.$axios({
        url: "/webCash/taskInfo",
        method: "get",
        params: {
          tid: this.$route.query.tid,
          inviteCode: this.$route.query.referrer
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.userInfo = result.data.data.userInfo;
          this.$store.state.cashNum = result.data.data.userInfo.cashNum;
          this.taskInfo = result.data.data.taskInfo;
          this.gameInfo = result.data.data.gameInfo;
          this.initTask = result.data.data.taskInfo.initTask;
          this.advanceTaskList = result.data.data.taskInfo.advanceTaskList;
          this.expTaskList = result.data.data.taskInfo.expTaskList;
        } else if (result.data.code == 0) {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
          this.$router.push("/index");
        }
      });
    },
    getTaskReward(subTid) {
      this.isLoading = true;
      this.$axios({
        url: "/webCash/taskReward",
        method: "post",
        params: {
          tid: this.$route.query.tid,
          subTid: subTid
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.getTaskInfo();
          this.rewardNum = result.data.data.rewardNum;
          this.rewardDialog = true;
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    goUrl(url) {
      window.open(url, "_blank");
    },
    goApp(url) {
      window.location.href = url;
    }
  },
  created() {
    this.getTaskInfo();
  }
};
</script>

<style>
#task-info-layout {
  max-width: 500px;
  margin: auto;
}

#task-info-layout .index-top {
  padding-bottom: 2%;
}

#task-info-layout .i-page-header {
  overflow: hidden;
  padding: 4% 4% 0 4%;
}

#task-info-layout .el-page-header__left::after {
  display: none;
}

#task-info-layout .i-gift-code-btn,
#task-info-layout .bottom-menu-bar .el-button {
  border: none;
  background: none;
}

#task-info-layout .el-drawer {
  border-radius: 20px 20px 0 0 !important;
}
</style>
