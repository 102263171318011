<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="gold-log-layout">
      <div class="gl-page-header">
        <img src="@/assets/common/leftArrow.png" @click="$router.push('/index')" style="float: left;width: 24px;margin-top: 10px;margin-right: 10px;" />
        <img src="@/assets/common/bejoyitIcon.png" @click="$router.push('/index')" style="float: left;width: 36px;margin-right: 6px;" />
        <span style="float: left;vertical-align: middle;margin-top: 10px;">Cash-Coupon Log</span>
        <div style="float: right;">
          <div style="float: right;background-color: rgba(251, 236, 143, 1);padding: 3px 7px 4px 7px;border-radius: 20px;margin: 6px 0 0 0;">
            <span style="font-size: 14px;color: black;text-decoration: none;vertical-align: middle;margin-right: 2px;color: rgba(87, 36, 13, 1);font-weight: 600;">
              {{ $store.state.cashNum }}
            </span>
            <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
          </div>
        </div>
      </div>

      <p style="border: 1px solid rgba(240, 240, 240, 1);padding: 0;margin: 3% 0;"></p>

      <div style="margin-top: 5%;margin-left: 5%;margin-right: 5%;overflow-y: scroll;height: 84vh;">
        <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
          <div v-for="log in cashLog" :key="log.id" style="background-color: rgba(222, 231, 233, 1);margin-bottom: 3%;padding: 4%;border-radius: 8px;">
            <div style="overflow: hidden;color: rgba(0, 0, 0, 1);">
              <div style="float: left;margin-top: 3%;font-size: 22px;">
                <div v-if="log.rewardNum > 0" style="float: left;margin-top: 5%;">+{{log.rewardNum}}</div>
                <div v-else style="float: left;margin-top: 5%;">{{log.rewardNum}}</div>
                <img src="@/assets/common/cash1Icon.png" style="margin-left: 4px;width: 24px;" />
              </div>
              <div style="float: right;text-align: right;">
                {{log.desc}}
                <div style="margin-top: 10%;">
                  {{log.logDate}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="cashLog == ''">
          <img src="@/assets/common/noIcon.png" style="margin-top: 30%;width: 150px;" />
          <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
        </div>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{failMessage}}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo: {
        cashNum: 0,
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      failMessage: null,
      checkDialog: false,
      cashLog: [],
      busy: false,
      pageNum: 0,
      isLast: false
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.busy = true;
        this.pageNum++;
        this.isLoading = true;
        this.$axios({
          url: "/webCash/cashLog",
          method: "get",
          params: {
            pageNum: this.pageNum
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            if (this.pageNum == 1) {
              this.userInfo = result.data.data.userInfo;
              this.$store.state.cashNum = result.data.data.userInfo.cashNum;
            }
            result.data.data.log.forEach((element) => {
              this.cashLog.push(element);
            });
            this.isLast = result.data.data.isLast;
          }
        });
        this.busy = false;
        if (this.failMessage != null) {
          this.checkDialog = true;
        }
      }
    }
  },
  created() {}
};
</script>

<style>
#gold-log-layout {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 500px;
  background-color: white;
}
#gold-log-layout .gl-page-header {
  overflow: hidden;
  padding: 4% 4% 0 4%;
}
</style>
